.contact .row {
    display: flex;
    flex-wrap: wrap;
  }
  
  .contact .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  
  .contact .newsletter-inner {
    height: 100%;
    transition: transform 0.3s ease-in-out;
  }
  
  .contact .newsletter-inner:hover {
    transform: scale(1.05);
  }
  
  .contact img.img-fluid {
    width: 100%;
    height: auto;
    transition: transform 0.3s ease-in-out;
  }
  
  .contact img.img-fluid:hover {
    transform: scale(1.1);
  }
  
  .contact .newsletter-content p {
    text-align: justify;
  }
  
  .contact .newsletter-content ul {
    text-align: justify;
  }
  