.about .newsletter {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.about .newsletter h2,
.about .newsletter h3 {
  font-weight: bold;
}

.about .newsletter p,
.about .newsletter ul {
  font-size: 16px;
  margin-bottom: 20px;
  text-align: justify;
}

.about .newsletter-inner {
  height: 100%; /* Added height property */
  transition: transform 0.3s ease-in-out;
}

.about .newsletter-inner:hover {
  transform: scale(1.05);
}

.about .img-fluid {
  width: 100%;
  height: auto;
  transition: transform 0.3s ease-in-out;
}

.about .img-fluid:hover {
  transform: scale(1.1);
}
