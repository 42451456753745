/* @font-face {
  font-family: "Lato";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url(public/storage/fonts/10155ba347/slatov23s6u8w4bmutphjxsaui-qnixg7eu0.woff2)
    format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
} */

@import url("https://fonts.googleapis.com/css2?family=Kantumruy+Pro:ital,wght@0,100..700;1,100..700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100..900;1,100..900&display=swap");
@font-face {
  font-family: "Lato";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  color: #000;
  src: url(https://nest.botble.com/storage/fonts/10155ba347/slatov23s6u8w4bmutphjxsaxc-qnixg7q.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
.dash-main {
  font-family: "Lato";
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  color: #000;
  src: url(https://nest.botble.com/storage/fonts/10155ba347/slatov23s6u8w4bmutphjxsaxc-qnixg7q.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
  background-color: black !important;
}
@font-face {
  font-family: "Lato";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  color: #000;
  src: url(https://nest.botble.com/storage/fonts/10155ba347/slatov23s6u-w4bmutphjxsi5wq-fqftx9897sxz.woff2)
    format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: "Lato";
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  src: url(https://nest.botble.com/storage/fonts/10155ba347/slatov23s6u-w4bmutphjxsi5wq-gwftx9897g.woff2)
    format("woff2");
  color: #000;
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  color: #000;
  src: url(https://nest.botble.com/storage/fonts/10155ba347/slatov23s6uyw4bmutphjxawxiwtfcfq7a.woff2)
    format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  color: #000;
  src: url(https://nest.botble.com/storage/fonts/10155ba347/slatov23s6uyw4bmutphjx4wxiwtfcc.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  color: #000;
  src: url(https://nest.botble.com/storage/fonts/10155ba347/slatov23s6u9w4bmutphh6uvswapgq3q5d0n7w.woff2)
    format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  color: #000;
  src: url(https://nest.botble.com/storage/fonts/10155ba347/slatov23s6u9w4bmutphh6uvswipgq3q5d0.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

@font-face {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  color: #000;
  src: url(https://nest.botble.com/storage/fonts/10155ba347/squicksandv306xktdszam9ie8kbpra-hjfqnyudyp7bh.woff2)
    format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 400;
  color: #000;
  font-display: swap;
  src: url(https://nest.botble.com/storage/fonts/10155ba347/squicksandv306xktdszam9ie8kbpra-hjvqnyudyp7bh.woff2)
    format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(https://nest.botble.com/storage/fonts/10155ba347/squicksandv306xktdszam9ie8kbpra-hk1qnyudypw.woff2)
    format("woff2");
  color: #000;
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

@font-face {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  color: #000;
  src: url(https://nest.botble.com/storage/fonts/10155ba347/squicksandv306xktdszam9ie8kbpra-hjfqnyudyp7bh.woff2)
    format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  color: #000;
  src: url(https://nest.botble.com/storage/fonts/10155ba347/squicksandv306xktdszam9ie8kbpra-hjvqnyudyp7bh.woff2)
    format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  color: #000;
  src: url(https://nest.botble.com/storage/fonts/10155ba347/squicksandv306xktdszam9ie8kbpra-hk1qnyudypw.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

@font-face {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  color: #000;
  src: url(https://nest.botble.com/storage/fonts/10155ba347/squicksandv306xktdszam9ie8kbpra-hjfqnyudyp7bh.woff2)
    format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  color: #000;
  src: url(https://nest.botble.com/storage/fonts/10155ba347/squicksandv306xktdszam9ie8kbpra-hjvqnyudyp7bh.woff2)
    format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  color: #000;
  src: url(https://nest.botble.com/storage/fonts/10155ba347/squicksandv306xktdszam9ie8kbpra-hk1qnyudypw.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

@font-face {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  color: #000;
  src: url(https://nest.botble.com/storage/fonts/10155ba347/squicksandv306xktdszam9ie8kbpra-hjfqnyudyp7bh.woff2)
    format("woff2");
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1,
    U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}

@font-face {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  color: #000;
  src: url(https://nest.botble.com/storage/fonts/10155ba347/squicksandv306xktdszam9ie8kbpra-hjvqnyudyp7bh.woff2)
    format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

@font-face {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  color: #000;
  src: url(https://nest.botble.com/storage/fonts/10155ba347/squicksandv306xktdszam9ie8kbpra-hk1qnyudypw.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}

.page_speed_2119873640 {
  display: none;
}
.detail-page {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  color: #000;
  src: url(https://nest.botble.com/storage/fonts/10155ba347/squicksandv306xktdszam9ie8kbpra-hk1qnyudypw.woff2)
    format("woff2");
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA,
    U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215,
    U+FEFF, U+FFFD;
}
.hovermain:hover {
  background-color: rgb(201, 201, 201);
  padding: 10px;
  border-radius: 10px;
  color: #fff;
}

/* .page_speed_703129441 {
  background-image: url(public/storage/general/newsletter-background-image.png) !important;
} */

.page_speed_202390995 {
  background-color: #000 !important;
  color: #fff !important;
}

.page_speed_1915701198 {
  max-width: 1170px;
}

.page_speed_1349294217 {
  background-color: #000 !important;
  color: #fff !important;
  border: 1px solid #fff !important;
}
.preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;
  background-color: rgba(255, 255, 255, 0.82);
}

.preloader .preloader-loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: block;
}

.preloader .preloader-loading::after {
  content: " ";
  display: block;
  border-radius: 50%;
  border-width: 1px;
  border-style: solid;
  -webkit-animation: lds-dual-ring 0.5s linear infinite;
  animation: lds-dual-ring 0.5s linear infinite;
  width: 40px;
  height: 40px;
  border-color: var(--color-brand) transparent var(--color-brand) transparent;
}

.table {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  color: #000;
  src: url(https://nest.botble.com/storage/fonts/10155ba347/squicksandv306xktdszam9ie8kbpra-hjvqnyudyp7bh.woff2)
    format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
.form-control {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  color: #000;
  src: url(https://nest.botble.com/storage/fonts/10155ba347/squicksandv306xktdszam9ie8kbpra-hjvqnyudyp7bh.woff2)
    format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

.form-group label {
  font-family: "Quicksand";
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  color: #000;
  src: url(https://nest.botble.com/storage/fonts/10155ba347/squicksandv306xktdszam9ie8kbpra-hjvqnyudyp7bh.woff2)
    format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB,
    U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
.dropdown_main {
  /* background-color: #fff; */
  font-size: 16px !important;
  border: 2px solid !important;
}

.home-slide-cover .hero-slider-content-2 {
  padding-left: 0;
  padding-right: 50px;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@-webkit-keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.zoom-effect {
  overflow: hidden;
  position: relative;
  transition: transform 0.4s;
  border-radius: 15px;
}

.zoom-effect:hover img {
  transform: scale(1.1);
}

.top-to-btm {
  position: relative;
}

.icon-position {
  position: fixed;
  bottom: 30px;
  right: 20px;
  z-index: 20;
}

.icon-style {
  background-color: #551b54;
  border: 2px solid #fff;
  border-radius: 50%;
  height: 50px;
  width: 50px;
  color: #fff;
  cursor: pointer;
  animation: movebtn 3s ease-in-out infinite;
  transition: all 0.5s ease-in-out;
}

.icon-style:hover {
  animation: none;
  background: #fff;
  color: #551b54;
  border: 2px solid #551b54;
}

@keyframes movebtn {
  0% {
    transform: translateY(0px);
  }
  25% {
    transform: translateY(20px);
  }
  50% {
    transform: translateY(0px);
  }
  75% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0px);
  }
}

.sec-title__title {
  margin: 0;
  font-size: 20px;
  line-height: 52px;
  font-weight: 700;
  margin: 0;
}
.sec-title__title > span {
  position: relative;
  color: var(--ienet-base, #df2339);
  overflow: hidden;
}
.sec-title__title > span::after {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 7px;
  content: "";
  animation: widths 3s linear infinite;
  background-color: var(--ienet-base, #df2339);
  -webkit-mask: url('data:image/svg+xml;utf8,<svg viewBox="0 0 229 7" xmlns="http://www.w3.org/2000/svg"><path d="M229 2.67187C228.139 2.92187 227.429 2.96484 226.707 2.95703C222.57 2.91015 218.428 2.83984 214.291 2.80468C211.131 2.77734 207.866 2.55468 204.835 2.83984C199.487 3.34374 194.151 2.95703 188.826 3.09374C182.696 3.24999 176.505 3.19531 170.336 3.16015C164.983 3.1289 159.63 2.92578 154.26 3.05078C152.173 3.09765 150.057 2.87109 147.969 3.02343C144.893 3.24999 141.839 3.21093 138.774 3.0039C137.375 2.91015 136.042 3.14843 134.67 3.20312C131.039 3.35156 127.402 3.37109 123.765 3.41015C123.126 3.41796 122.432 3.44531 121.855 3.56249C119.8 3.97265 117.59 4.15624 115.441 4.15234C111.782 4.14843 108.234 4.55078 104.558 4.52734C98.3223 4.48437 92.0754 4.74218 85.8342 4.72656C79.8317 4.71484 73.8292 4.67187 67.8267 4.71484C65.5501 4.73046 63.279 4.76953 61.019 4.88281C60.2916 4.91796 59.5587 4.98437 58.8368 4.95703C54.0115 4.78906 49.3028 5.25781 44.5385 5.46484C40.2629 5.64843 35.9929 5.88671 31.7062 6.03515C26.4478 6.21484 21.206 6.53124 15.992 6.89453C11.6886 7.19531 7.57959 6.80468 3.46502 6.36718C1.94913 6.20703 0.072307 4.93749 0.00567426 4.21484C-0.0609585 3.49609 0.577605 3.12499 2.15458 2.95703C6.19141 2.51953 10.356 2.49999 14.4317 2.13281C23.366 1.32421 32.4003 0.816401 41.4901 0.558588C48.6253 0.355463 55.7662 0.273432 62.9181 0.285151C68.4375 0.292963 73.9624 0.164057 79.4763 0.226557C85.4066 0.292963 91.3258 0.164057 97.2506 0.164057C106.585 0.164057 115.924 0.0117134 125.259 0.00390089C133.382 -0.0039116 141.506 0.0742134 149.624 0.281245C164.394 0.660151 179.187 0.886713 193.94 1.38671C204.074 1.73046 214.219 1.96484 224.352 2.28515C225.868 2.32812 227.329 2.48046 229 2.67187Z"/></svg>');
  mask: url('data:image/svg+xml;utf8,<svg viewBox="0 0 229 7" xmlns="http://www.w3.org/2000/svg"><path d="M229 2.67187C228.139 2.92187 227.429 2.96484 226.707 2.95703C222.57 2.91015 218.428 2.83984 214.291 2.80468C211.131 2.77734 207.866 2.55468 204.835 2.83984C199.487 3.34374 194.151 2.95703 188.826 3.09374C182.696 3.24999 176.505 3.19531 170.336 3.16015C164.983 3.1289 159.63 2.92578 154.26 3.05078C152.173 3.09765 150.057 2.87109 147.969 3.02343C144.893 3.24999 141.839 3.21093 138.774 3.0039C137.375 2.91015 136.042 3.14843 134.67 3.20312C131.039 3.35156 127.402 3.37109 123.765 3.41015C123.126 3.41796 122.432 3.44531 121.855 3.56249C119.8 3.97265 117.59 4.15624 115.441 4.15234C111.782 4.14843 108.234 4.55078 104.558 4.52734C98.3223 4.48437 92.0754 4.74218 85.8342 4.72656C79.8317 4.71484 73.8292 4.67187 67.8267 4.71484C65.5501 4.73046 63.279 4.76953 61.019 4.88281C60.2916 4.91796 59.5587 4.98437 58.8368 4.95703C54.0115 4.78906 49.3028 5.25781 44.5385 5.46484C40.2629 5.64843 35.9929 5.88671 31.7062 6.03515C26.4478 6.21484 21.206 6.53124 15.992 6.89453C11.6886 7.19531 7.57959 6.80468 3.46502 6.36718C1.94913 6.20703 0.072307 4.93749 0.00567426 4.21484C-0.0609585 3.49609 0.577605 3.12499 2.15458 2.95703C6.19141 2.51953 10.356 2.49999 14.4317 2.13281C23.366 1.32421 32.4003 0.816401 41.4901 0.558588C48.6253 0.355463 55.7662 0.273432 62.9181 0.285151C68.4375 0.292963 73.9624 0.164057 79.4763 0.226557C85.4066 0.292963 91.3258 0.164057 97.2506 0.164057C106.585 0.164057 115.924 0.0117134 125.259 0.00390089C133.382 -0.0039116 141.506 0.0742134 149.624 0.281245C164.394 0.660151 179.187 0.886713 193.94 1.38671C204.074 1.73046 214.219 1.96484 224.352 2.28515C225.868 2.32812 227.329 2.48046 229 2.67187Z"/></svg>');
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-position: left bottom;
  mask-position: left bottom;
  -webkit-mask-size: cover;
  mask-size: cover;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.wrapper {
  width: 87%;
  margin: 0 auto;
}
main {
  padding-top: 175px;
}
@media screen and (max-width: 1080px) {
  main {
    padding-top: 90px;
  }
}
@media screen and (max-width: 640px) {
  main {
    padding-top: 85px;
  }
  .wrapper {
    width: 85%;
  }
}

button {
  color: #094c27;
  svg {
    color: #000;
  }
}
.slick-prev,
.slick-next {
  display: none !important;
}
.slider-wrapper {
  max-width: 95%;
  margin: auto;
}
.slider-container {
  max-height: unset;
}
@media screen and (max-width: 1080px) {
  .slider-container {
    max-height: 400px;
  }
}
