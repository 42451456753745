.vendor label{
    font-size:larger;
    
}
.vendor input{
    height: 3rem;
    border-color: #a19b9b;
    align-self: center;
    padding: 10px;
}
.vendor .option{
    height: 3rem;
    border-color: black;
    align-items: center;

}